html, body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  color: white;
}

.App-link {
  color: #61dafb;
}

#background {
  background: url("https://src.lynksen.com/codepen/bg-noise.png"), #1e1e1e;
  /* background-size: 300px 300px; */
  animation: 250ms steps(10, end) 0s infinite alternate-reverse none running
    noise-animation;
}

.canvas-container{
  width: 100%;
  height: 100%;
}


@keyframes noise-animation {
  0% {
    background-position: 0% 0%;
  }
  10% {
    background-position: -5% -5%;
  }
  20% {
    background-position: -10% 5%;
  }
  30% {
    background-position: 5% -10%;
  }
  40% {
    background-position: -5% 15%;
  }
  50% {
    background-position: -10% 5%;
  }
  60% {
    background-position: 5% 5%;
  }
  70% {
    background-position: 0% 10%;
  }
  80% {
    background-position: -5% -5%;
  }
  90% {
    background-position: 10% 5%;
  }
}
@media (max-width: 1023px){
  .pageref{

    /* Apply the gradient mask */
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 2rem, black calc(100%- 2rem), transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0%, black 2rem, black calc(100% - 2rem), transparent 100%);

    /* Ensure it covers the full element */
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
  .projectpage{
    /* Apply the gradient mask */
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 2rem, black calc(100%- 2rem), transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0%, black 2rem, black calc(100% - 2rem), transparent 100%);
  
    /* Ensure it covers the full element */
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    }
}

@media (min-width: 1024px){
.projectpage{
    /* Apply the gradient mask */
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 4rem, black calc(100%- 4rem), transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0%, black 4rem, black calc(100% - 4rem), transparent 100%);

    /* Ensure it covers the full element */
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
  .pageref{

    /* Apply the gradient mask */
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 6rem, black calc(100%- 6rem), transparent 100%);
    mask-image: linear-gradient(to right, transparent 0%, black 6rem, black calc(100% - 6rem), transparent 100%);
  
    /* Ensure it covers the full element */
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes slideAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.looper__listInstance {
  display: flex;
  width: max-content;

  animation: slideAnimation linear infinite;
}

.sort-btn{
  @apply rounded-full w-fit py-1 px-2 lg:px-3 transition-all duration-300 hover:bg-white/20;
  &.active{
    @apply  !bg-white text-black;
  }
}

/* .thumbnail-img {
  transition: all 0.1s ease-out;
} */
/* .thumbnail-img {
  transition: all 0.2s ease-out;
  filter: grayscale(100%) brightness(70%);
  -webkit-filter: grayscale(100%) brightness(70%);
  &.active {
    transform: scale(1);
    filter: grayscale(0%) brightness(100%);
    -webkit-filter: grayscale(0%) brightness(100%);
  }
} */
